/**
 * Calculates the percentage change between two values.
 * Handles special case when previous value is zero.
 * 
 * @param {number} current - The current value
 * @param {number} previous - The previous value
 * @returns {number} The percentage change
 */
export const calculatePercentChange = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
};

/**
 * Formats a number using compact notation.
 * 
 * @param {number} num - The number to format
 * @returns {string} The formatted number (e.g., 1K, 1M)
 */
export const formatCompactNumber = (num) => {
    return new Intl.NumberFormat("en-US", {
        notation: "compact",
        compactDisplay: "short",
    }).format(num);
};

/**
 * Formats a number using standard notation with comma separators.
 * 
 * @param {number} num - The number to format
 * @returns {string} The formatted number (e.g., 1,343)
 */
export const formatNumber = (num, currency = false) => {
    return new Intl.NumberFormat("en-US", {
        style: currency ? "currency" : "decimal",
        currency: "USD",
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(num);
};

/**
 * Formats a duration in seconds to a MM:SS string format.
 * 
 * @param {number} seconds - The duration in seconds
 * @returns {string} The formatted duration string
 */
export const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

/**
 * Formats a call duration in seconds according to specified rules.
 * If under 1 hour, displays in MM:SS format.
 * If over 1 hour, converts to hours and rounds up.
 * 
 * @param {number} seconds - The duration in seconds
 * @returns {string} The formatted duration string
 */
export const formatCallDuration = (seconds) => {
    if (typeof seconds !== 'number') {
        console.warn('Invalid input type for formatCallDuration:', typeof seconds);
        return 'Invalid Duration';
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours < 1) {
        // If under 1 hour, return MM:SS format
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
        // If over 1 hour, convert to hours and round up
        return `${Math.ceil(seconds / 3600)} hour${Math.ceil(seconds / 3600) > 1 ? 's' : ''}`;
    }
};

/**
 * Formats a decimal number as a percentage string.
 * 
 * @param {number} num - The decimal number to format (e.g., 0.75 for 75%)
 * @returns {string} The formatted percentage string with two decimal places
 */
export const formatPercentage = (num) => {
    return (num * 100).toFixed(2) + "%";
};

/**
 * Processes chart data by aggregating and sorting it.
 * 
 * @param {Object} aggregatedData - The aggregated data object
 * @returns {Array} Sorted array of data objects
 */
export const processChartData = (aggregatedData) => {
    const sortedData = Object.entries(aggregatedData)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => b.value - a.value);

    if (sortedData.length <= 5) {
        return sortedData;
    }

    const topCategories = sortedData.slice(0, 4);
    const otherCategories = sortedData.slice(4);

    const otherValue = otherCategories.reduce((sum, item) => sum + item.value, 0);
    topCategories.push({ name: "Other", value: otherValue });

    return topCategories;
};

/**
 * Formats a phone number string from "1231231234" to "(123) 123-1234"
 * 
 * @param {string} phoneNumber - The phone number to format
 * @returns {string} The formatted phone number
 */
export const formatPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string' || phoneNumber.length !== 10) {
        console.warn('Invalid phone number:', phoneNumber);
        return phoneNumber;
    }

    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6);

    return `(${areaCode}) ${firstPart}-${secondPart}`;
};

export const getWeekBounds = (date) => {
    // Get the start of week (Sunday)
    const startOfWeek = new Date(date);
    startOfWeek.setUTCDate(date.getUTCDate() - date.getUTCDay());
    startOfWeek.setUTCHours(0, 0, 0, 0);

    // Get the end of week (Saturday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6);
    endOfWeek.setUTCHours(23, 59, 59, 999);

    return { startOfWeek, endOfWeek };
};

export const adjustDateRangeToFullWeeks = (startDate, endDate) => {
    // Get start and end of weeks containing the dates
    const { startOfWeek: adjustedStart } = getWeekBounds(startDate);
    const { endOfWeek: adjustedEnd } = getWeekBounds(endDate);

    return {
        startDate: adjustedStart,
        endDate: adjustedEnd
    };
};

// Modified aggregateData to ensure complete weeks
export const aggregateData = (data, aggregationType) => {
    const aggregatedData = [];
    const dataMap = new Map();

    // If aggregating by week, adjust input data to only include complete weeks
    if (aggregationType === 'week' && data.length > 0) {
        const dates = data.map(item => new Date(item.date));
        const { startDate, endDate } = adjustDateRangeToFullWeeks(
            new Date(Math.min(...dates)),
            new Date(Math.max(...dates))
        );

        // Filter data to only include complete weeks
        data = data.filter(item => {
            const date = new Date(item.date);
            return date >= startDate && date <= endDate;
        });
    }

    data.forEach(item => {
        const date = new Date(item.date);
        let key;

        switch (aggregationType) {
            case 'week': {
                const { startOfWeek } = getWeekBounds(date);
                key = formatDate(startOfWeek);
                break;
            }
            case 'month':
                key = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}`;
                break;
            default: // day
                key = formatDate(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())));
        }

        if (!dataMap.has(key)) {
            dataMap.set(key, { date: key, ...item });
        } else {
            const existingData = dataMap.get(key);
            Object.keys(item).forEach(metric => {
                if (metric !== 'date' && typeof item[metric] === 'number') {
                    existingData[metric] = (existingData[metric] || 0) + item[metric];
                }
            });
        }
    });

    return Array.from(dataMap.values());
};

export const getAggregationType = (startDate, endDate) => {
    const daysDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    if (daysDiff <= 31) return 'day';
    if (daysDiff <= 183) return 'week';
    return 'month';
};

export const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
};

export const formatSourceMedium = (str) => {
    const [source, medium] = str.split(' / ');
    return `${capitalizeWords(source)} (${capitalizeWords(medium)})`;
};

// Helper function to find metric value by name
export const getMetricValue = (row, metricHeaders, metricName) => {
    const index = metricHeaders.findIndex(h => h.name === metricName);
    return index >= 0 ? row.metricValues[index]?.value : null;
};

// Helper function to find dimension value by name
export const getDimensionValue = (row, dimensionHeaders, dimensionName) => {
    const index = dimensionHeaders.findIndex(h => h.name === dimensionName);
    return index >= 0 ? row.dimensionValues[index]?.value : null;
};

export const normalizeDate = (date) => {
    if (!date) return null;
    // Create a new date, setting it to midnight in the local timezone
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
};

export const formatDate = (dateInput) => {
    if (!dateInput) return 'Invalid Date';
    
    // Handle string inputs
    if (typeof dateInput === 'string') {
        if (/^\d{4}-\d{2}-\d{2}$/.test(dateInput)) {
            return dateInput;
        }
        if (/^\d{8}$/.test(dateInput)) {
            return dateInput.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        }
    }

    // Convert to local midnight
    const date = normalizeDate(dateInput);
    if (!date || isNaN(date.getTime())) {
        console.warn('Invalid date:', dateInput);
        return 'Invalid Date';
    }

    // Format as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const parseDate = (dateString) => {
    if (!dateString) return null;
    return normalizeDate(dateString);
};

export const prettyDate = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date)) {
        return '';
    }
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

export const prettyDateSmall = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date)) {
        return '';
    }
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}/${day}`;
};

export const getDateRangeLabel = (dateStr) => {
    if (!dateStr) return '';
    const date = normalizeDate(dateStr);
    return prettyDate(date);
};
