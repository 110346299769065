import React from 'react';
import { formatPhoneNumber } from '../utils/dataProcessing';

const getAddressTitle = (mapsId, accounts) => {
    const location = accounts.gbpLocations[mapsId];
    if (location && location.address) {
        const { addressLines, locality, administrativeArea, postalCode } = location.address;
        return `${addressLines.join(' ')}, ${locality}, ${administrativeArea} ${postalCode}`;
    }
    return 'Unknown Location';
};

// Define lazy loaded components
const GA4Component = React.lazy(() => import('../components/Datasources/GA4'));
const GSCComponent = React.lazy(() => import('../components/Datasources/GSC'));
const GBPComponent = React.lazy(() => import('../components/Datasources/GBP'));
const EcommerceComponent = React.lazy(() => import('../components/Datasources/Ecommerce'));
const CallTrackingComponent = React.lazy(() => import('../components/Datasources/CallTracking'));
const ReviewsComponent = React.lazy(() => import('../components/Datasources/Reviews'));

export const dataTypes = [
    {
        key: 'ecommerce',
        name: 'E-commerce',
        icon: '/ecommerce-icon.png',
        component: EcommerceComponent,
        getTitle: (property, accounts) => accounts.ga4Properties[property]?.displayName,
        parentKey: 'ga4'
    },
    {
        key: 'ga4',
        name: 'Visibility & Traffic',
        icon: '/ga_icon.svg',
        component: GA4Component,
        getTitle: (property, accounts) => accounts.ga4Properties[property]?.displayName,
    },
    {
        key: 'ga4-organic',
        name: 'Organic Traffic',
        icon: '/organic-icon.png',
        component: GA4Component,
        getTitle: (property, accounts) => accounts.ga4Properties[property]?.displayName,
        isOrganic: true
    },
    {
        key: 'gsc',
        name: 'Google Search Console',
        icon: '/gsc_icon.svg',
        component: GSCComponent,
        getTitle: (siteUrl) => siteUrl,
    },
    {
        key: 'gbp',
        name: 'Google Business Profile',
        icon: '/gbp_icon.svg',
        component: GBPComponent,
        getTitle: getAddressTitle,
    },
    {
        key: 'call-tracking',
        name: 'Call Tracking',
        icon: '/phone_icon.svg',
        component: CallTrackingComponent,
        getTitle: (id, accounts, data) => {
            if (data && data.length > 0 && data[0].tracking) {
                return `${formatPhoneNumber(data[0].tracking)}`;
            }
            return 'Call Tracking';
        },
    },
    {
        key: 'reviews',
        name: 'Reviews',
        icon: '/reviews_icon.svg',
        component: ReviewsComponent,
        getTitle: getAddressTitle,
    },
];
